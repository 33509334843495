import Cookies from 'js-cookie';
import { findKey, isEmpty, groupBy, isBoolean } from 'lodash';
import { mapState, mapActions } from 'vuex';
import listCountriesGDPR from '@model/const/countries-gdpr.ts';
import listFreeDomains from '@model/const/emails-services-free.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import utils from '@utils/form.js';
import { Logger } from '@utils/logger.ts';

const EMAIL_DOMAINS_BLACKLIST = ['acronis.'];

export default {
    data: () => ({
        isLoading: false,
        searchParams: null,
        isVisibleOptIn: false,
        validatedEmailOptIn: '',
        guid: null,
    }),

    mounted() {
        this.searchParams = new URLSearchParams(this.queryStringData);
    },

    computed: {
        ...mapState({
            accountData: (state) => state.user?.data || null,
            location: (state) => state.geolocation?.location || null,
            settingsEvent: (state) => state.events?.event?.items?.[0] || null,
            formElementsDefault: (state) => state.slices.items?.['s-list-form-elements'] || {},
            formSpecialOffer: (state) => state.slices.items?.['s-preset-form-special-offer'] || {},
            formGlobalSettings: (state) => state.slices.items?.['s-form-global-settings'] || {},
            queryStringData: (state) => state.trial?.queryStringData || {},
        }),

        formUpdatePresetName() {
            return this.form?.update?.preset || 's-preset-form-trial-update';
        },

        settingsFormUpdate() {
            return this.$store.state.slices.items?.[this.formUpdatePresetName] || {};
        },

        payloadAccount() {
            const companySize = {
                '6-10': '1-10',
                '51-100': '11-100',
                '101-250': '101-500',
                '251-500': '101-500',
                '501-1000': '501-1000',
                '1001-5000': '1001-5000',
                '5001+': '5000+',
            };
            const companyType = {
                'Corporate End-Customer': 'EndUser',
                'Service Provider': 'ServiceProvider',
            };
            const industry = {
                'Educational Services': 'Education',
                'Public Administration': 'Government',
                'Health Care and Social Assistance': 'Healthcare & Pharmaceutical',
            };
            const subscription = Number(this.userData.gdpr_opt_in);
            const isCorporate = companyType[this.userData.company_type];
            const payload = {
                country: this.userData.country,
                email: this.userData.email,
                first: this.userData.first_name,
                is_beta: this.userData.is_beta || false,
                last: this.userData.last_name,
                password: this.userData.password,
                phone: this.userData.phone,
                postponed_activation: true,
                state: this.userData.state || '',
                subscription_corporate: isCorporate ? subscription : 0,
                subscription_home: !isCorporate ? subscription : 0,
                subscription_language: 'en-US',
                type: 'consumer',
            };
            if (isCorporate) {
                payload.company_name = this.userData.company;
                payload.company_size = companySize[this.userData.of_Employees__c] || '1-10';
                payload.company_type = companyType[this.userData.company_type];
                payload.industry = industry[this.userData.industry] || '';
                payload.type = 'enterprise';
            }
            if (this.userData.postal_code) {
                payload.zip = this.userData.postal_code;
            }

            return payload;
        },

        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        countryCode() {
            return this.locale.split('-').pop();
        },

        language() {
            return this.locale.split('-').shift();
        },

        showPostalCodeField() {
            const result = this.form.country === 'DE';
            if (!result) {
                this.form.postal_code = '';
            }
            return result;
        },

        showStatesField() {
            const result = this.form.country === 'US';
            if (!result) {
                this.form.state = '';
            }
            return result;
        },

        labelSelect() {
            return {
                placeholder: this.formElementsDefault.errors?.select?.placeholder,
                noMatchText: this.formElementsDefault.errors?.select?.noMatch,
                noDataText: this.formElementsDefault.errors?.select?.noData,
                searchPlaceholder: this.formElementsDefault.errors?.select?.searchPlaceholder,
            };
        },

        isCountryGDPR() {
            return listCountriesGDPR.includes(this.form.country);
        },

        setAbbreviation() {
            return this.abbr || this.$parent.abbr || 'ABR';
        },

        setFormType() {
            return this.formType || this.$parent.formType || 'register';
        },

        formRecaptchaText() {
            return this.content?.recaptcha?.text || this.formElementsDefault.recaptcha || '';
        },
        web46922() {
            // WEB-46922
            const result = {
                1: 'homepage-2024-control',
                2: 'homepage-2024-b',
                3: 'homepage-2024-c',
                4: 'homepage-2024-d',
            };
            const value = Cookies.get('_vis_opt_exp_123_combi') || '';
            return result[value] || '';
        },
        web47483() {
            const url = window.location.href;
            if (!url.includes('products/cloud/trial')) return false;

            const demoButtonClickDate = localStorage.getItem('demoButtonClickDate');
            if (!demoButtonClickDate) return false;
            const demoButtonHopsCount = localStorage.getItem('demoButtonClickHops') || 0;
            const demoButtonClickHops = parseInt(demoButtonHopsCount, 10);

            return {
                demoButtonClickDate,
                demoButtonClickHops,
            };
        },
        setUtmTerm() {
            return this.web47483 || this.web46922;
        },
    },

    methods: {
        ...mapActions({
            getOptInStatus: 'trial/getOptInStatus',
        }),

        setErrorMessage(type) {
            return this.formElementsDefault.errors?.[type];
        },

        setFocus(str = '') {
            const name = this.settingsForm?.focus || str;
            this.$nextTick(() => {
                const elements = this.$refs.form?.$el || {};
                if (!isEmpty(elements)) {
                    const elementID = name ? findKey(elements, { name }) : 0;
                    elements[elementID]?.focus();
                }
                if (this.settingsForm?.validate) {
                    this.$refs.form.validate(() => false);
                }
            });
        },

        setFocusErrorField() {
            const element = this.$refs.form.fields.find((i) => i.validateState === 'error');
            const elementName = element?.prop || '';
            if (elementName === 'email') return;
            this.setFocus(elementName);
        },

        isVisibleField(item, type) {
            if (item.type === 'hidden') {
                this.form[item.name] = item.value;
                return false;
            }

            if (!item.type || !type.includes(item.type)) {
                return false;
            }

            if (item.visible) {
                if (!item.visible.list || !item.visible.field) {
                    return false;
                }

                const dependent = this.form[item.visible.field];
                const isVisible = item.visible.list.includes(dependent);

                if (!isVisible) {
                    this.form[item.name] = '';
                }

                return isVisible;
            }

            if (item.name === 'state') {
                return this.showStatesField;
            }

            if (/postal_?code/i.test(item.name)) {
                return this.showPostalCodeField;
            }

            if (item.getReseller && !this.resellerList.length) return false;
            if (item.getDistributor && !this.distributorList.length) return false;

            return true;
        },

        isFreeEmail(email) {
            if (!email) return false;
            const domain = email.replace(/.*?@/, '');
            return listFreeDomains.includes(domain);
        },

        isBusinessEmail() {
            const fields = this.form.registration?.fields || [];
            const email = fields.find((x) => x.name === 'email') || {};
            return email.isBusinessEmail;
        },

        isBusinessOnlyEmail() {
            const fields = this.form.registration?.fields || [];
            const email = fields.find((x) => x.name === 'email') || {};
            return email.isBusinessOnlyEmail;
        },

        isRequired(item) {
            return this.content.hasAsterisks && item.required;
        },

        isRequiredSelect(item) {
            return !['country', 'state'].includes(item.name) && this.isRequired(item);
        },

        replaceLocale(str = '') {
            return str.replace(/\/LOCALE\//g, `/${this.locale}/`);
        },

        validateEmail(rule, value, callback) {
            if (!value) {
                return callback(new Error(rule.errorMessage));
            }
            if (rule?.errorMessageMailgun?.message) {
                if (rule.errorMessageMailgun.email !== this.form.email) return callback();
                return callback(new Error(rule.errorMessageMailgun.message));
            }
            if (!utils.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (!utils.checkIfEmailHasAddressSign(value)) {
                return callback(new Error(this.setErrorMessage('missingEmailSymbol')));
            }
            if (!utils.checkIfEmailValid(value)) {
                return callback(new Error(this.setErrorMessage('invalidEmail')));
            }
            if (rule?.isBusinessOnlyEmail && this.isFreeEmail(value)) {
                return callback(new Error(rule.errorMessageBusiness));
            }
            if (rule?.isBusinessEmailByCountry) {
                const coutries = new Set(['in', 'bd', 'bt', 'io', 'lk', 'mv', 'np']);
                const country = this.form?.country?.toLowerCase() || '';
                if (coutries.has(country) && this.isFreeEmail(value)) {
                    return callback(new Error(rule.errorMessageBusiness));
                }
            }
            return callback();
        },

        validatePhone(rule, value, callback) {
            const cleanedValue = value?.replaceAll(' ', '');
            if (!cleanedValue || cleanedValue === '+') {
                return callback(new Error(rule.errorMessage));
            }
            if (!utils.checkIfFieldLengthValid('input', cleanedValue)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (!utils.checkIfPhoneValid(cleanedValue)) {
                return callback(new Error(this.setErrorMessage('invalidPhone')));
            }
            return callback();
        },

        validatePassword(rule, value, callback) {
            if (!value) {
                return callback(new Error(rule.errorMessage));
            }
            if (!utils.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }
            if (value.length < 8) {
                return callback(new Error(this.setErrorMessage('shortPassword')));
            }

            return callback();
        },

        validateFieldValue(rule, value, callback) {
            const hasValue = value && value.toString().replaceAll(' ', '').length;
            if (!hasValue && rule.required) {
                return callback(new Error(rule.errorMessage));
            }
            if (!utils.checkIfFieldLengthValid(rule.fieldType, value)) {
                const type = rule.fieldType === 'input' ? 'invalidInputLength' : 'invalidTextAreaLength';
                return callback(new Error(this.setErrorMessage(type)));
            }
            return callback();
        },

        /**
        * Extended validation for first and last name - WEB-46734
        */
        validateNameFieldExtended(rule, value, callback) {
            const fieldName = rule.field === 'first_name' ? 'First name' : 'Last name';
            const errorMessage = `Please use only letters in the ${fieldName}—no numbers, symbols, or extra spaces.`;

            if (!value || value.length < 2) {
                return callback(new Error(errorMessage));
            }
            if (!utils.checkIfFieldLengthValid('input', value)) {
                return callback(new Error(this.setErrorMessage('invalidInputLength')));
            }

            const validNameRegex = /^[A-Za-z\u00C0-\u017F]+(?:[-' ][A-Za-z\u00C0-\u017F]+)*$/;
            const hasNumbers = /\d/.test(value);
            const invalidSymbolsRegex = /[!@#$%^&*()_+={}[\]:;"<>,.?/\\|~]/;
            const hasInvalidSymbols = invalidSymbolsRegex.test(value);
            const hasInvalidWhitespace = /^\s|\s{2,}|\s$/.test(value);

            if (hasNumbers || hasInvalidSymbols || hasInvalidWhitespace || !validNameRegex.test(value)) {
                return callback(new Error(errorMessage));
            }

            return callback();
        },

        sendDataLayer(data) {
            const abbr = this.setAbbreviation.toLowerCase();
            const type = this.setFormType.toLowerCase();

            utils.sendDataLayer({
                ...data,
                eventLabel: data.eventLabel || `${abbr}_${type}`,
            });
        },

        exceptionList() {
            if (!Array.isArray(this.content?.fields)) return [];
            return this.content.fields.filter((item) => item.notUseAccountData).map((item) => item.name);
        },

        setAccountData() {
            // To pre-fill the form, we use the user's data from the personal account (account.acronis.com)
            // The same field in different forms on the site has different names (company or companyName)
            // But in the personal account this field has a name (company_name)
            // Below is the mapping between the names of the fields on the site and in the personal account that must be pre-filled

            const prefill = {
                country: 'country',
                company: 'company_name',
                companyName: 'company_name',
                company_type: {
                    HomeUser: 'Prosumer End-Customer',
                    EndUser: 'Corporate End-Customer',
                    ServiceProvider: 'Service Provider',
                    Reseller: 'Reseller',
                },
                email: 'email',
                firstName: 'first',
                first_name: 'first',
                industry: {
                    Other: 'Other',
                    Technology: 'Information',
                    'Healthcare & Pharmaceutical': 'Health Care and Social Assistance',
                    Education: 'Educational Services',
                    Manufacturing: 'Manufacturing',
                    Government: 'Public Administration',
                    Transportation: 'Transportation and Warehousing',
                },
                lastName: 'last',
                last_name: 'last',
                phone: 'phone',
                zip: 'zip',
            };

            const exceptions = this.exceptionList();
            const suitableKeys = Object.keys(prefill).filter((key) => key in this.form && !exceptions.includes(key));

            const { custom, generic } = groupBy(suitableKeys, (key) => (typeof prefill[key] === 'object' ? 'custom' : 'generic'));

            (generic || []).forEach((item) => {
                this.form[item] = this.accountData?.[prefill[item]] || this.form[item] || '';
            });

            (custom || [])
                .map((item) => {
                    const option = this.accountData?.[item];
                    const optionCode = prefill[item]?.[option];
                    return optionCode ? { item, optionCode } : null;
                })
                .filter(Boolean)
                .forEach(({ item, optionCode }) => {
                    const currentForm = this.content?.fields;
                    const comparator = (el) => el.code === optionCode;

                    let option = null;

                    if (Array.isArray(currentForm)) {
                        option = currentForm
                            .flatMap((el) => el.options)
                            .filter(Boolean)
                            .find(comparator);

                        if (!option && this.formElementsDefault?.options?.[item]) {
                            option = this.formElementsDefault.options[item].find(comparator);
                        }
                    }

                    if (!option && currentForm?.companyType?.list) {
                        option = currentForm.companyType.list.find(comparator);
                    }

                    if (!option && currentForm?.industry?.options) {
                        option = currentForm.industry.options.find(comparator);
                    }

                    this.form[item] = option?.code || '';
                });

            this.checkEmailOptIn('isAuthorized');
        },

        payloadMarketo() {
            const params = this.form?.flow || {};
            const userID = utils.getCookies(utils.UID_COOKIE_NAME) || '';
            const clientId = utils.getCIDval() || '[ERROR] Client ID not generated';

            let payload = {
                ...params.needToAddEmptyValues,
                ...this.userData,
                ck: this.userData.ck,
                google_analytics_clientid: clientId,
                google_analytics_id: clientId,
                google_analytics_trackingid: utils.GA_TRACKING_ID,
                google_analytics_userid: userID,
                language: this.language,
                lead_source: 'Website',
                lead_source_detail: '',
                locale: this.countryCode,
                new_lead_source_detail: params.marketo?.new_lead_source_detail || 'Request Trial',
                osauid: this.guid,
                product_title: this.setProductTitle(),
                conversion_page: window.location.href,
            };

            if (this.recaptchaResponse) {
                // eslint-disable-next-line no-underscore-dangle
                payload._recaptchaResponse = this.recaptchaResponse;
            }

            if (sessionStorage?.getItem('x-opp-id')) {
                payload['x-opp-id'] = sessionStorage.getItem('x-opp-id');
            }

            payload.munchkinId = params.ckToMunchkin ? payload.ck : '';
            payload.webuid = params.osaToWebUid ? payload.osauid : '';

            if (!isEmpty(params.needToAddEmptyValues)) {
                Object.keys(payload)
                    .filter((i) => typeof payload[i] === 'undefined')
                    .forEach((i) => { payload[i] = ''; });
            } else {
                Object.keys(payload)
                    .filter((i) => ['password', 'phoneCountryCode'].includes(i) || !payload[i] || i.startsWith('dont_send_field'))
                    .forEach((i) => delete payload[i]);
            }

            payload = { ...payload, ...params.marketo };

            if (params.marketo?.title === 'setTitle') {
                payload.title = this.setTitle();
            }

            if (params.marketo?.country === 'setCountry') {
                const country = this.location?.data?.country?.code || '';
                payload.country = country;
            }

            payload.business_email = !this.isFreeEmail(this.userData.email);

            localStorage.setItem('trial_es_osauid', payload.osauid);
            localStorage.setItem('trial_es_email', this.userData.email);

            if ('gdpr_opt_in' in this.userData) {
                payload.gdpr_opt_in = this.userData.gdpr_opt_in || false;
            }

            if (params.isFormExpressSignup && payload.country === 'US') {
                payload.gdpr_opt_in = false;
            }

            // WEB-45086
            if (payload.country) {
                payload = {
                    ...payload,
                    state: payload.state || null,
                    postal_code: payload.postal_code || null,
                };
            }

            if (isBoolean(this.userData.terms)) {
                payload.terms = !this.isCountryGDPR || payload.terms || null;
            }

            const result = {
                ...payload,
                ...utils.labelsUTM({
                    utm_term: this.setUtmTerm || params.marketo?.utm_term || null,
                    utm_medium: params.marketo?.utm_medium || 'trial',
                    utm_source: params.marketo?.utm_source || `acronis-${this.locale}`,
                }),
                ...utils.sfdcCampaign({
                    sfdc_campaign_id: params.marketo?.sfdc_campaign_id || null,
                    sfdc_campaign_status: params.marketo?.sfdc_campaign_status || null,
                }),
                ...utils.sfdcCampaignSmart(),
            };

            const compare = ([key, value]) => [key, value === 'NULL' ? null : value];
            return Object.fromEntries(Object.entries(result).map(compare));
        },

        setTitle() {
            const form = this.form?.registration?.fields || [];
            const formField = form.find((item) => item.name === 'company_type');
            if (!formField?.options) return '';
            const option = formField?.options.find((item) => item.code === this.userData.company_type) || {};
            return option?.name || '';
        },

        setProductTitle() {
            const product = {
                Reseller: 'Acronis Cyber Protect',
                'Corporate End-Customer': 'Acronis Cyber Protect',
                'Service Provider': 'Acronis Cyber Protect Cloud',
                'Prosumer End-Customer': 'Acronis True Image',
            };

            const productTitle = product[this.userData.company_type] || '';

            const form = this.form?.registration?.fields || [];
            const formField = form.find((item) => item.setProductTitle);

            if (formField && formField.options) {
                const field = formField.name;
                const option = formField.options.find((item) => item.code === this.userData[field]) || {};
                return option?.product_title || productTitle;
            }

            return productTitle;
        },

        setDefaultOptIn() {
            this.isVisibleOptIn = false;
            this.form.opt_in = false;
            this.form.gdpr_opt_in = false;
        },

        async checkEmailOptIn(type) {
            if (this.content?.skipEmailOptIn) return;

            if (this.isLoading && type !== 'isAuthorized') {
                return;
            }

            if (!'email|country|isAuthorized'.includes(type)) {
                return;
            }

            if (!this.form.email || !this.form.country) {
                return;
            }

            if (this.form.email === this.validatedEmailOptIn && type === 'email') {
                return;
            }

            if (this.isVisibleOptIn) {
                if (this.form?.form_id === 'RESOURCE_CENTER') {
                    this.form.gdpr_opt_in = false;
                }
            }

            if (this.form.country === 'US') {
                this.setDefaultOptIn();
                this.form.gdpr_opt_in = true;
                return;
            }

            if (this.validateEmail(this.rules.email, this.form.email, (errorMessage) => errorMessage)) {
                return;
            }

            try {
                this.isLoading = true;
                const response = await this.getOptInStatus(this.form.email);

                if (!response.success) {
                    this.setDefaultOptIn();
                    return;
                }

                const isOptIn = response.data?.optIn || false;

                this.form.gdpr_opt_in = isOptIn;

                this.isVisibleOptIn = !isOptIn;

                this.validatedEmailOptIn = this.form.email;
            } catch {
                this.setDefaultOptIn();
            } finally {
                this.isLoading = false;
            }
        },

        prepareFormToValidate() {
            Object
                .keys(this.form)
                .filter((key) => typeof this.form[key] === 'string')
                .forEach((key) => { this.form[key] = this.form[key].trim(); });
        },

        sendEventMarketingSubscription(isChecked) {
            if (!isChecked) {
                return;
            }
            utils.sendDataLayer({
                eventCategory: 'form',
                eventAction: 'clicked checkbox',
                eventLabel: 'marketing subscription',
            });
        },

        formLabelText(item) {
            if (item?.placeholder) return '';
            return item?.label || this.formElementsDefault.labels?.[item.name] || item.name;
        },

        formErrorText(item) {
            if (item.error) return item.error;
            const type = item?.type?.startsWith('select') ? 'select' : 'input';
            const text = this.formElementsDefault.errors?.[item.name];
            return text || this.formElementsDefault.errorsDefault?.[type] || 'This field is required';
        },

        formListOption(name) {
            return this.formElementsDefault?.options?.[name] || [];
        },

        formCheckboxText(item) {
            if (item.text) return this.replaceLocale(item.text);
            const buttonName = item.buttonName || '';
            const text = this.formElementsDefault?.checkboxes?.[item.name] || '';
            return text.replace('@buttonName', buttonName);
        },

        handleError(error) {
            const defaultError = this.formElementsDefault.errors?.apiErrorCodes?.default || 'Error';
            this.errorTitle = this.formElementsDefault.errors?.apiErrorCodes?.[error.code] || defaultError;
            this.errorCode = error.code;
            this.isErrorShown = true;
            Logger.error({ error });
        },

        setEvent(item) {
            if (item.event) return item.event;
            return item && item.sendEventGA ? { label: item.to, ...item.sendEventGA } : {};
        },

        sendNotSpEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'NotSP',
                notSP: 'true',
            });
        },

        async checkIfSuitableForNotSpEvent() {
            try {
                const utmTerm = this.$route.query?.utm_term;

                if (!['form', 'ccform'].includes(utmTerm)) {
                    return false;
                }

                const emailDomain = this.form.email.split('@').pop();

                // Email domain is listed in the "free email domains" list
                if (listFreeDomains.includes(emailDomain)) {
                    return true;
                }

                // Email domain is listed in the hardcoded array of domains
                const isInBlacklist = EMAIL_DOMAINS_BLACKLIST.some((domainPart) => emailDomain.includes(domainPart));
                if (isInBlacklist) {
                    return true;
                }

                // For "form" only: email domain is NOT listed in the SFDC database of verified SP companies
                if (utmTerm === 'form') {
                    const { data } = await this.$store.dispatch('events/checkEmailInSpDomain', this.form.email);
                    return !data.isSp;
                }

                // For "ccform" only: email domain is NOT listed in 6sense validated DB
                if (utmTerm === 'ccform') {
                    const isInList = await this.$store.dispatch('events/checkEmailIn6senseList', emailDomain);
                    return !isInList;
                }

                return false;
            } catch (error) {
                Logger.error({ error });
                return false;
            }
        },
    },

    async serverPrefetch() {
        const locale = this.locale;

        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 's-preset-form-special-offer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-form-global-settings', locale: LOCALE_DEFAULT }),
        ];

        if (!this.settingsEvent) {
            promises.push(this.$store.dispatch('slices/getSyncedData', { slice: 's-list-form-elements', locale }));
        }
        if (this.form?.update) {
            promises.push(this.$store.dispatch('slices/getSyncedData', { slice: this.formUpdatePresetName, locale }));
        }
        await Promise.all(promises);
    },
};
