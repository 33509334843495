export function getElementPath(element: HTMLElement): string {
    const segments = [];

    let cursor = element;

    while (cursor && cursor.id !== 'router-view') {
        if (cursor.dataset?.sliceName) {
            segments.push(cursor.dataset.sliceName);
        }

        cursor = cursor.parentElement;
    }

    return segments.reverse().join(' > ');
}
