// This mixin should be installed globally in app-factory.ts
// Necessary for Kayden's project for better eventLocation in GA.
// The idea here is to hook to mounted and to assign the data-slice-name attribute directly to elements,
// and then to consume the attr to calculate the path from the event target to the app root element
// See: https://pmc.acronis.work/browse/WEB-48408

import { kebabCase } from 'lodash';

export default {
    mounted() {
        if (!this.$el?.dataset) return;

        const componentName = kebabCase(this.$options.name ?? '');

        const isSkipped =
            !componentName ||
            !componentName.includes('-') || // one-word names are reserved to Vue internals and real DOM tags
            componentName.startsWith('a-') || // components
            componentName.startsWith('vue-') || // 3rd party libs
            componentName.startsWith('el-'); // @uikit

        if (!isSkipped) {
            this.$el.dataset.sliceName = componentName;
        }
    },
};
