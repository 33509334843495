export function parseDynamicPagePath(path: string = ''): { slug: string, draftKey: string | undefined } {
    const segments = path.split('/').filter(Boolean);

    if (!segments.length) {
        return {
            slug: '',
            draftKey: undefined,
        };
    }

    if (segments[segments.length - 1].startsWith('draft-')) {
        const draftKeySegment = segments.pop();

        return {
            slug: segments.join('/'),
            draftKey: draftKeySegment.replace('draft-', ''),
        };
    }

    return {
        slug: segments.join('/'),
        draftKey: undefined,
    };
}
