<template>
    <component
        :is="tagName"
        :type="to ? null : nativeType"
        :target="realTarget"
        :rel="realRel"
        :href="href"
        :title="title"
        :data-has-no-href="hasNoHref"
        @click="handleClick"
    >
        <slot />
    </component>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import utm from '@core/mixins/utm.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import { getElementPath } from '@utils/analytics.ts';
import { normalizeHref } from '@utils/normalize-href.js';

export default {
    name: 'ADynamicLink',
    mixins: [analytics, utm],
    props: {
        to: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: undefined,
        },
        rel: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        nativeType: {
            type: String,
            default: 'button',
        },
        hasNoHref: {
            type: Boolean,
            default: false,
        },
        doNotChangeLocation: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['click'],
    computed: {
        tagName() {
            return this.to ? 'a' : 'button';
        },
        injectedLocale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        link() {
            return (this.to || '').trim();
        },
        realTarget() {
            const defaultTarget = this.target || '_self';
            const newWindowTarget = '_blank';

            if (!this.isAbsoluteLink()) {
                return defaultTarget;
            }

            return this.isAcronisLink() || this.isAcronisScsLink() ? defaultTarget : newWindowTarget;
        },
        realRel() {
            const defaultRel = this.rel || undefined;
            const noFollow = 'nofollow';

            if (!this.isAbsoluteLink()) {
                return defaultRel;
            }

            return this.isAcronisLink() || this.isAcronisScsLink() ? defaultRel : noFollow;
        },
        href() {
            if (this.getSessionUTM.length) {
                const url = this.setQueryString(this.to || '/');
                if (url) return url;
            }
            return normalizeHref(this.to, this.injectedLocale);
        },
    },
    methods: {
        isAbsoluteLink() {
            return (
                this.link.startsWith('http:') ||
                this.link.startsWith('https://') ||
                this.link.startsWith('//')
            );
        },
        isAcronisLink() {
            return this.link.split('//').pop().toLowerCase().startsWith('www.acronis.com');
        },
        isAcronisScsLink() {
            return this.link.includes('acronisscs.com');
        },
        handleClick(event) {
            const href = this.href || '';

            if (!this.event?.doNotSendGA) {
                // Process GA event
                const incomingGA = this.event;
                const linkTarget = /\.(pdf|zip|pptx)$/.test(href) ? 'documents' : 'link';
                const linkContext = linkTarget === 'link' ? 'click' : 'download';

                const gaData = {
                    event: incomingGA.event || 'Acronis',
                    eventCategory: incomingGA.category || 'Interactions',
                    eventAction: incomingGA.action || linkTarget,
                    eventLabel: incomingGA.label || href || '',
                    eventContext: incomingGA.context || linkContext,
                    eventContent: incomingGA.content || event.target.innerText || '',
                    eventLocation: incomingGA.location || getElementPath(event.target),
                    eventButton: incomingGA.button || '',
                };
                if (incomingGA.formFieldA) gaData.formFieldA = incomingGA.formFieldA;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(gaData);
            }

            if (this.doNotChangeLocation) {
                event.preventDefault();
                this.$emit('click', event);
                document.activeElement.blur();
                return;
            }

            // handle the link
            if (href) {
                event.preventDefault();

                const isKeyboardHack =
                    this.nativeType === 'a' &&
                    !href.startsWith('tel:') &&
                    (event.metaKey || event.ctrlKey);

                if (isKeyboardHack || this.realTarget === '_blank') {
                    window.open(href, '_blank');
                } else {
                    window.location.href = href;
                }
            }

            this.$emit('click', event);
        },
    },
};
</script>
