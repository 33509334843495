export const SITE_ID_WWW = 'www.acronis.com';
export const SITE_ID_SOLUTIONS = 'solutions.acronis.com';
export const SITE_ID_DEVELOPER = 'developer.acronis.com';
export const SITE_ID_EVENTS = 'acronis.events';
export const SITE_ID_SCS = 'acronisscs.com';
export const SITE_ID_TRUEIMAGE = 'trueimage.com';
export const SITE_ID_ACADEMY = 'academy.acronis.com';
export const SITE_ID_TOOLBOX = 'toolbox.stage.web.gcp.acronis.com';
export const SITE_INT_ID_WWW = 1;
export const SITE_INT_ID_SCS = 2;
export const SITE_INT_ID_DEVELOPER = 3;
export const SITE_INT_ID_TRUEIMAGE = 4;

export const ONETRUST_ID = {
    [SITE_ID_WWW]: 'c570eb43-1169-4222-914c-38a09bd70ca0',
    [SITE_ID_SOLUTIONS]: 'c570eb43-1169-4222-914c-38a09bd70ca0',
    [SITE_ID_DEVELOPER]: 'c570eb43-1169-4222-914c-38a09bd70ca0',
    [SITE_ID_TRUEIMAGE]: 'c570eb43-1169-4222-914c-38a09bd70ca0',
    [SITE_ID_ACADEMY]: 'c570eb43-1169-4222-914c-38a09bd70ca0',
    [SITE_ID_EVENTS]: '06871c56-5960-4395-a518-8667facb6d86',
    [SITE_ID_SCS]: 'c8378c84-dd78-4d85-bb0c-a31d0af7f04f',
};
